@media print
   {
    @page {
        size: A4;
        margin: 25mm 25mm 25mm 25mm;
    }
    body {
       font-size: 11pt;
    }
    a[href]:after {
	content: none !important;
    }
    
    h3, h2, .company {
        font-size: 12pt;
        font-weight: bold !important;
	}

    img.avatar {
        display: none;
    }
   
    .main-wrapper .time{
        position: static;
        left: 0;
        top: 0;
        display: block;
        float: right;
        color: black !important;
        -webkit-print-color-adjust: exact; 
   }
    .languages-container, .contact-container, .interests-container{
    }
 
    .sidebar-wrapper .profile-container{
	padding: 0px !important;	
    }
    .sidebar-wrapper {
        position: static;
        width: inherit;
        min-height: 300px !important;
        -webkit-print-color-adjust: exact; 
    }

    .remove-container.container-block {
       /*display: none;*/
    }

    footer,  {
       display: none;
    }

    .skillset .level-bar-inner {
       background-color: black !important;
       -webkit-print-color-adjust: exact; 
   }  
   
   .fa-inverse,
   .fa-inverse:after,
   .fa-inverse:before {
     color: #ffffff !important;
   }

}
